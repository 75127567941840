import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    fallbackLng: 'pt',
    lng: localStorage.getItem('language') || 'pt',
    supportedLngs: ['pt', 'en', 'es'],
    debug: process?.env?.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
