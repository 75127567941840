import React from 'react'
import PropTypes from 'prop-types'

import { MuiPickersUtilsProvider as PickersProvider } from '@material-ui/pickers'
import { AppProvider } from 'contexts/AppContext'
import { SessionProvider } from 'contexts/SessionContext'
import SnackbarProvider from 'contexts/SnackbarProvider'
import { ThemeProvider } from 'contexts/ThemeContext'

import DateFnsUtils from '@date-io/date-fns'
import localeBr from 'date-fns/esm/locale/pt-BR'
import localeEn from 'date-fns/esm/locale/en-US'
import localeEs from 'date-fns/esm/locale/es'
import JavascriptTimeAgo from 'javascript-time-ago'
import pt from 'javascript-time-ago/locale/pt'
import en from 'javascript-time-ago/locale/en'
import es from 'javascript-time-ago/locale/es'

import ErrorBoundary from '@honeybadger-io/react'
import instance from 'api/api'
import { Provider } from 'react-redux'
import HoneybadgerConfig from 'utils/HoneybadgerConfig'
import NetworkService from 'utils/NetworkService'
import VersionChecker from 'utils/VersionChecker'
import store from 'utils/store'
import { KBarContext } from './KBarContext'
import { QueryClientProvider } from './QueryClientProvider'
import { useTranslation } from 'react-i18next'

NetworkService.setupInterceptors(store)
NetworkService.setupInterceptors(store, instance)

const getLocale = lang => {
  if (lang === 'pt') {
    return localeBr
  } else if (lang === 'en') {
    return localeEn
  } else if (lang === 'es') {
    return localeEs
  }
  return localeBr
}

export const AppProviders = ({ children }) => {
  const { i18n } = useTranslation()

  const language = i18n.language

  if (language === 'es') {
    JavascriptTimeAgo.locale(es)
  } else if (language === 'en') {
    JavascriptTimeAgo.locale(en)
  } else {
    JavascriptTimeAgo.locale(pt)
  }

  const locale = getLocale(language)

  return (
    <ErrorBoundary honeybadger={HoneybadgerConfig}>
      <QueryClientProvider>
        <Provider store={store}>
          <SessionProvider>
            <AppProvider>
              <ThemeProvider>
                <SnackbarProvider>
                  <PickersProvider utils={DateFnsUtils} locale={locale}>
                    <KBarContext>{children}</KBarContext>
                  </PickersProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </AppProvider>
          </SessionProvider>

          <VersionChecker />
        </Provider>
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

AppProviders.propTypes = {
  children: PropTypes.node,
}
